import gql from "graphql-tag";

export const CALL_FIELDS_FRAGMENT = gql`
  fragment CallFields on Phonecall {
    id
    createdDateTime
    createdByUserId
    eventDateTime
    status
    dialledPartyNumber
    callingPartyNumber
    dialledAgent
    callingAgent
    source
    topics
    tags {
      id
      name
      color
    }
    sentimentValue
    callDirection
    callDuration
    keyFacts
    sentenceMonitors {
      isMatched
      distance
      monitorTopic {
        id
        label
      }
    }
    follows {
      createdByUserId
    }
  }
`;

export const GET_CALL = gql`
  query GET_PHONECALL($id: UUID!) {
    phonecall(id: $id) {
      id
      createdDateTime
      createdByUserId
      eventDateTime
      dialledPartyNumber
      callingPartyNumber
      dialledAgent
      callingAgent
      source
      callDirection
      sentiment
      sentimentValue
      status
      transcription
      comments{
        id
        parentCommentId
        createdDateTime
        text
        quotedText
        quotedTextStartWord
        quotedTextEndWord
        user{
          email
          firstName
          lastName
        }
      }
      transcript {
        id
        words {
          text
          speaker
          start
          end
          textConfidence
          speakerConfidence
        }
      }
      keyFacts
      topics
      follows {
        createdByUserId
      }
      sentenceMonitors {
        isMatched
        distance
        monitorTopic {
          id
          label
        }
      }
      tags {
        id
        name
        color
      }
    }
  }
`;

export const GET_CALLS = gql`
  query GET_PHONECALLS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $startDate: DateTime
    $endDate: DateTime
    $filterSentiment: [SentimentValue]!
    $filterCallDirection: [CallDirection!]
    $filterMinCallDuration: TimeSpan
  ) {
    phonecalls(
      first: $first
      last: $last
      order: { eventDateTime: DESC }
      after: $after
      before: $before
      where: {
        and: [
          { eventDateTime: { gte: $startDate } }
          { eventDateTime: { lte: $endDate } }
          { sentimentValue: { in: $filterSentiment } }
          { callDirection: { in: $filterCallDirection } }
          { callDuration: { gte: $filterMinCallDuration } }
          {
            or: [
              { dialledAgent: { contains: $search } }
              { callingAgent: { contains: $search } }
              { dialledPartyNumber: { contains: $search } }
              { callingPartyNumber: { contains: $search } }
            ]
          }
        ]
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...CallFields
      }
    }
  }
  ${CALL_FIELDS_FRAGMENT}
`;

export const GET_CALLS_FOLLOWED = gql`
  query GET_PHONECALLS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $startDate: DateTime
    $endDate: DateTime
    $filterSentiment: [SentimentValue]!
    $filterCallDirection: [CallDirection!]
    $filterMinCallDuration: TimeSpan
  ) {
    phonecalls(
      first: $first
      last: $last
      order: { eventDateTime: DESC }
      after: $after
      before: $before
      where: {
        and: [
          { follows: { any: true } }
          { eventDateTime: { gte: $startDate } }
          { eventDateTime: { lte: $endDate } }
          { sentimentValue: { in: $filterSentiment } }
          { callDirection: { in: $filterCallDirection } }
          { callDuration: { gte: $filterMinCallDuration } }
          {
            or: [
              { dialledAgent: { contains: $search } }
              { callingAgent: { contains: $search } }
              { dialledPartyNumber: { contains: $search } }
              { callingPartyNumber: { contains: $search } }
            ]
          }
        ]
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...CallFields
      }
    }
  }
  ${CALL_FIELDS_FRAGMENT}
`;

export const GET_CALLS_TAGGED = gql`
  query GET_PHONECALLS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $startDate: DateTime
    $endDate: DateTime
    $filterSentiment: [SentimentValue]!
    $filterCallDirection: [CallDirection!]
    $filterTags: [UUID]
    $filterMinCallDuration: TimeSpan
  ) {
    phonecalls(
      first: $first
      last: $last
      order: { eventDateTime: DESC }
      after: $after
      before: $before
      where: {
        and: [
          { eventDateTime: { gte: $startDate } }
          { eventDateTime: { lte: $endDate } }
          { sentimentValue: { in: $filterSentiment } }
          { callDirection: { in: $filterCallDirection } }
          { callDuration: { gte: $filterMinCallDuration } }
          { tags: { some: { tagId: { in: $filterTags } } } }
          {
            or: [
              { dialledAgent: { contains: $search } }
              { callingAgent: { contains: $search } }
              { dialledPartyNumber: { contains: $search } }
              { callingPartyNumber: { contains: $search } }
            ]
          }
        ]
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...CallFields
      }
    }
  }
  ${CALL_FIELDS_FRAGMENT}
`;

export const GET_CALLS_TAGGED_FOLLOWED = gql`
  query GET_PHONECALLS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $startDate: DateTime
    $endDate: DateTime
    $filterSentiment: [SentimentValue]!
    $filterCallDirection: [CallDirection!]
    $filterTags: [UUID]
    $filterMinCallDuration: TimeSpan
  ) {
    phonecalls(
      first: $first
      last: $last
      order: { eventDateTime: DESC }
      after: $after
      before: $before
      where: {
        and: [
          { follows: { any: true } }
          { eventDateTime: { gte: $startDate } }
          { eventDateTime: { lte: $endDate } }
          { sentimentValue: { in: $filterSentiment } }
          { callDirection: { in: $filterCallDirection } }
          { callDuration: { gte: $filterMinCallDuration } }
          { tags: { some: { tagId: { in: $filterTags } } } }
          {
            or: [
              { dialledAgent: { contains: $search } }
              { callingAgent: { contains: $search } }
              { dialledPartyNumber: { contains: $search } }
              { callingPartyNumber: { contains: $search } }
            ]
          }
        ]
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...CallFields
      }
    }
  }
  ${CALL_FIELDS_FRAGMENT}
`;