import React, { useState } from 'react';
import SpeakerLine from './SpeakerLine';
import Comments from './comments/Comments';
import { Menu, Item, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useMutation } from '@apollo/client';
import { CREATE_PHONECALL_COMMENT, DELETE_PHONECALL_COMMENT, CREATE_PHONECALL_COMMENT_REPLY } from '../gql/mutations';
import { toast } from 'react-toastify';
import { RootCommentProps } from './comments/RootComment';

interface Word {
    text: string;
    speaker: number;
    start: number;
    end: number;
    textConfidence: number;
    speakerConfidence: number;
}

interface HighlightSequence {
    start: number;
    end: number;
}

interface Data {
    words: Word[];
}

interface Comment {
    id?: string;
    parentCommentId?: string;
    quotedText: string;
    text: string;
    quotedTextStartWord: number;
    quotedTextEndWord: number;
    createdDateTime: string;
    startWordIndex: number;
    endWordIndex: number;
    user?: {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
    };
    comments: Array<{
        user?: {
            id: string;
            email: string;
            firstName: string;
            lastName: string;
        };
        text: string;
        timestamp: string;
    }>;
}

const MENU_ID = 'transcript-context-menu';

const renderWords = (
    data: Data,
    currentTime: number,
    onSeekTime?: ((time: number) => void) | null,
    comments?: Comment[]
): JSX.Element[] => {
    const lines: JSX.Element[] = [];
    let currentLine: JSX.Element[] = [];
    let currentSpeaker = data.words[0]?.speaker;
    let currentLineStartTime = data.words[0]?.start;

    // Find the word with the closest timestamp to currentTime
    let closestWordIndex = -1;
    let minTimeDiff = Infinity;

    data.words.forEach((word, index) => {
        const avgTime = (word.start + word.end) / 2;
        const timeDiff = Math.abs(avgTime - currentTime);
        if (timeDiff < minTimeDiff) {
            minTimeDiff = timeDiff;
            closestWordIndex = index;
        }
    });

    data.words.forEach((word, index) => {
        const isCurrentWord = index === closestWordIndex;
        
        // Check if this word is within any comment's highlighted range
        const isHighlighted = comments?.some(comment => {
            if(comment.startWordIndex === null || comment.endWordIndex === null) return false;
            return index >= comment.startWordIndex && index <= comment.endWordIndex;
        });

        if (word.speaker !== currentSpeaker && word.speaker !== -1) {
            lines.push(
                <SpeakerLine
                    key={`line-${lines.length}`}
                    speaker={currentSpeaker}
                    timestamp={currentLineStartTime}
                    onTimeClick={onSeekTime}
                >
                    {currentLine}
                </SpeakerLine>
            );

            currentLine = [];
            currentSpeaker = word.speaker;
            currentLineStartTime = word.start;
        }

        currentLine.push(
            <span
                key={index}
                style={{
                    backgroundColor: isHighlighted ? '#fff3cd' : undefined,
                    textDecoration: isCurrentWord ? 'underline' : undefined,
                    textDecorationThickness: isCurrentWord ? '2px' : undefined,
                    textUnderlineOffset: isCurrentWord ? '4px' : undefined
                }}
            >
                {word.text + " "}
            </span>
        );
    });

    if (currentLine.length > 0) {
        lines.push(
            <SpeakerLine
                key={`line-${lines.length}`}
                speaker={currentSpeaker}
                timestamp={currentLineStartTime}
                onTimeClick={onSeekTime}
            >
                {currentLine}
            </SpeakerLine>
        );
    }

    return lines;
};

interface TranscriptProps {
    data: Data;
    highlightSequences?: HighlightSequence[];
    currentTime: number;
    onSeekTime?: ((time: number) => void) | null;
    phonecallId: string;
    initialComments?: Comment[];
}

export const Transcript: React.FC<TranscriptProps> = ({
    data,
    currentTime = 0,
    onSeekTime,
    phonecallId,
    initialComments = []
}) => {
    const { show } = useContextMenu({
        id: MENU_ID,
    });
    const [comments, setComments] = useState<Comment[]>(
        initialComments.map(comment => ({
            ...comment,
            startWordIndex: comment.quotedTextStartWord,
            endWordIndex: comment.quotedTextEndWord
        }))
    );
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [createComment] = useMutation(CREATE_PHONECALL_COMMENT);
    const [createReply] = useMutation(CREATE_PHONECALL_COMMENT_REPLY);
    const [deleteComment] = useMutation(DELETE_PHONECALL_COMMENT);
    const [selectedText, setSelectedText] = useState<{
        text: string;
        range: Range | null;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        
        const selection = window.getSelection();
        if (selection && selection.toString().trim()) {
            // Store the current selection before showing context menu
            setSelectedText({
                text: selection.toString(),
                range: selection.getRangeAt(0).cloneRange()
            });
            show({ event });
        }
    };

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    };

    const handleAddCommentContextAction = ({ event }: { event: any }) => {
        if (!selectedText) return;

        const selectedTextContent = selectedText.text;
        if (!selectedTextContent) return;

        // Find the word indices that contain the selected text
        const allText = data.words.map(w => w.text).join(' ');
        const startCharIndex = allText.indexOf(selectedTextContent);
        if (startCharIndex === -1) return;

        // Count words before selection to get starting word index
        const textBeforeSelection = allText.substring(0, startCharIndex);
        const startWordIndex = textBeforeSelection.split(' ').length - 1;
        const endWordIndex = startWordIndex + selectedTextContent.split(' ').length - 1;
        
        const newComment : Comment = {
            id: `temp-${Date.now()}`,
            quotedText: selectedTextContent,
            text: '',
            createdDateTime: new Date().toISOString(),
            quotedTextStartWord: startWordIndex,
            quotedTextEndWord: endWordIndex,
            startWordIndex: startWordIndex,
            endWordIndex: endWordIndex,
            comments: []
        };

        setComments(prevComments => [...prevComments, newComment]);
        setShowCommentBox(true);
        setSelectedText(null); // Clear the selection after using it
    };

    const handleSaveComment = async (comment: RootCommentProps) => {
        try {
            if (!comment.id || !comment.id.startsWith('temp-')) {
                console.log("Comment already saved");
                return;
            }

            const response = await createComment({
                variables: {
                    phonecallId,
                    quotedText: comment.quotedText,
                    startWordIndex: comment.quotedTextStartWord,
                    endWordIndex: comment.quotedTextEndWord,
                    text: comment.text
                }
            });

            const savedComment = response.data.commentCreate.comment;
            setComments(prevComments => 
                prevComments.map(c => 
                    c.id === comment.id ? {
                        ...c,
                        id: savedComment.id,
                        text: savedComment.text,
                        createdDateTime: comment.createdDateTime,
                        user: savedComment.user,
                        startWordIndex: comment.quotedTextStartWord,
                        endWordIndex: comment.quotedTextEndWord,
                    } : c
                )
            );
        } catch (error) {
            console.error('Error saving comment:', error);
        }
    };

    const handleDeleteComment = async (commentId: string) => {
        if (!commentId || commentId.startsWith('temp-')) {
            // If comment hasn't been saved yet, just remove it from state
            setComments(prevComments => 
                prevComments.filter(c => c.id !== commentId)
            );
            return;
        }

        try {
            await deleteComment({
                variables: { id: commentId }
            });
            setComments(prevComments => 
                prevComments.filter(c => c.id !== commentId)
            );
            // Show success toast
            toast.success('Comment deleted successfully');
        } catch (error) {
            console.error('Error deleting comment:', error);
            // Show error toast
            toast.error('Failed to delete comment. Please try again.');
        }
    };

    const handleSaveReply = async (parentCommentId: string, text: string) => {
        try {
            const response = await createReply({
                variables: {
                    parentCommentId,
                    text
                }
            });

            const savedReply = response.data.commentCreateReply.comment;
            
            setComments(prevComments => [
                ...prevComments,
                {
                    id: savedReply.id,
                    parentCommentId: savedReply.parentCommentId,
                    text: savedReply.text,
                    createdDateTime: savedReply.createdDateTime,
                    user: savedReply.user,
                    quotedText: '', // These fields are not needed for replies
                    quotedTextStartWord: 0,
                    quotedTextEndWord: 0,
                    startWordIndex: 0,
                    endWordIndex: 0,
                    comments: []
                }
            ]);

            return savedReply;
        } catch (error) {
            console.error('Error saving reply:', error);
            toast.error('Failed to save reply. Please try again.');
            throw error;
        }
    };

    // Optional: Clear selection when clicking outside
    React.useEffect(() => {
        const handleClickOutside = () => {
            setSelectedText(null);
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div 
                style={{ display: 'flex', gap: '20px' }}
                onContextMenu={handleContextMenu}
                onTouchStart={(e) => e.stopPropagation()}
            >
                <div style={{ flex: '0.7' }}>
                    {renderWords(data, currentTime, onSeekTime, comments)}
                </div>
                <div style={{ flex: '0.3' }}>
                    <Comments 
                        commentList={comments
                            .filter(comment => !comment.parentCommentId)
                            .map(rootComment => ({
                                ...rootComment,
                                timestamp: formatDate(rootComment.createdDateTime),
                                comments: comments
                                    .filter(reply => reply.parentCommentId === rootComment.id)
                                    .sort((a, b) => new Date(a.createdDateTime).getTime() - new Date(b.createdDateTime).getTime())
                                    .map(reply => ({
                                        ...reply,
                                        timestamp: formatDate(reply.createdDateTime)
                                    }))
                            }))}
                        showCommentBox={showCommentBox}
                        onSaveComment={handleSaveComment}
                        onDeleteComment={handleDeleteComment}
                        onSaveReply={handleSaveReply}
                    />
                </div>
            </div>

            <Menu id={MENU_ID}>
                <Item onClick={handleAddCommentContextAction}>
                    Add Comment
                </Item>
            </Menu>
        </>
    );
};