import gql from "graphql-tag";

export const CREATE_PHONECALL_BOOKMARK = gql`
  mutation CREATE_PHONECALL_BOOKMARK($phonecallId: UUID!) {
    createPhonecallBookmark(input: { phonecallId: $phonecallId }) {
      phonecall {
        id
        follows {
          createdByUserId
        }
      }
    }
  }
`;

export const DELETE_PHONECALL_BOOKMARK = gql`
  mutation DELETE_PHONECALL_BOOKMARK($phonecallId: UUID!) {
    deletePhonecallBookmark(input: { phonecallId: $phonecallId }) {
      phonecall {
        id
        follows {
          createdByUserId
        }
      }
    }
  }
`;

export const CREATE_PHONECALL_COMMENT = gql`
  mutation CREATE_PHONECALL_COMMENT(
    $phonecallId: UUID!
    $quotedText: String!
    $startWordIndex: Int!
    $endWordIndex: Int!
    $text: String!
  ) {
    commentCreate(
      input: {
        phonecallId: $phonecallId
        quotedText: $quotedText
        startWordIndex: $startWordIndex
        endWordIndex: $endWordIndex
        text: $text
      }
    ) {
      comment {
        id
        text
        createdDateTime
        user{
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const CREATE_PHONECALL_COMMENT_REPLY = gql`
  mutation CREATE_PHONECALL_COMMENT_REPLY(
    $parentCommentId: UUID!
    $text: String!
  ) {
    commentCreateReply(
      input: {
        commentId: $parentCommentId
        text: $text
      }
    ) {
      comment {
        id
        text
        parentCommentId
        createdDateTime
        user{
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const DELETE_PHONECALL_COMMENT = gql`
  mutation DELETE_PHONECALL_COMMENT($id: UUID!) {
    commentDelete(input: { commentId: $id }) {
      comment {
        id
      }
    }
  }
`;