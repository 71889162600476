import { RootComment, RootCommentProps } from "./RootComment";

interface CommentsProps {
  phonecallId?: string;
  commentList?: Array<RootCommentProps>;
  showCommentBox?: boolean;
  commentPosition?: number;
  selectedText?: string;
  onClose?: () => void;
  onSaveComment: (comment: RootCommentProps) => Promise<void>;
  onDeleteComment: (commentId: string) => Promise<void>;
  onSaveReply: (parentCommentId: string, text: string) => Promise<any>;
}

export const Comments = ({
  commentList = [],
  onSaveComment,
  onDeleteComment,
  onSaveReply
}: CommentsProps) => {
  return (
    <div className="py-4 px-3 flex-shrink-0" style={{ width: 400 }}>
      {commentList.map((rootComment, i) => (
        <RootComment 
          key={rootComment.id || i}
          {...rootComment}
          onDelete={() => onDeleteComment(rootComment.id!)}
          onSave={(comment) => {
            if (comment.id && !comment.id.startsWith('temp-')) {
              // This is a reply
              return onSaveReply(comment.id, comment.text);
            }
            // This is a new comment
            return onSaveComment(comment);
          }}
          onCancel={() => {
            if (rootComment.id?.startsWith('temp-')) {
              onDeleteComment(rootComment.id);
            }
          }}
          isNew={!rootComment.id || rootComment.id.startsWith('temp-')}
        />
      ))}
    </div>
  );
};

export default Comments;
