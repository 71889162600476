import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Comment } from "./Comment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useQuery } from "@apollo/client";
import { GET_ME } from "../../../../queries/Users";

export interface RootCommentProps {
  id?: string;
  quotedText: string;
  text: string;
  quotedTextStartWord: number;
  quotedTextEndWord: number;
  createdDateTime: string;
  user?: {
    email: string;
    firstName: string;
    lastName: string;
  };
  comments: Array<{
    user?: {
      id: string;
      email: string;
      firstName: string;
      lastName: string;
    };
    text: string;
    createdDateTime: string;
  }>;
  showInput?: boolean;
  unsaved?: boolean;
  onDelete?: () => void;
  onSave?: (comment: RootCommentProps) => void;
  onCancel?: () => void;
  isNew?: boolean;
}

export const RootComment = ({ 
  id, 
  quotedText, 
  text, 
  quotedTextStartWord, 
  quotedTextEndWord, 
  createdDateTime, 
  user,
  comments, 
  showInput, 
  unsaved, 
  onDelete, 
  onSave, 
  onCancel,
  isNew = false 
}: RootCommentProps) => {
  const [commentText, setCommentText] = useState("");
  const [showReplyInput, setShowReplyInput] = useState(false);
  const { data: meData } = useQuery(GET_ME);

  const getUserInitials = (user: { firstName: string, lastName: string } | undefined) => {
    if (!user) return '??';
    return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
  };

  const handleSave = () => {
    onSave?.({
      id,
      quotedText,
      text: commentText,
      quotedTextStartWord,
      quotedTextEndWord,
      createdDateTime,
      comments,
      showInput,
      unsaved,
      onDelete,
      onSave,
      onCancel
    });
    setCommentText("");
    setShowReplyInput(false);
  };

  const handleDelete = async () => {
    if (!id) return;
    
    if (window.confirm('Are you sure you want to delete this comment?')) {
      onDelete?.();
    }
  };

  const showInputAndButtons = isNew || !id || id.startsWith('temp-') || showInput;

  return (
    <div className="mb-3 border rounded p-3 position-relative">
      {id && !id.startsWith('temp-') && (
        <div className="position-absolute" style={{ right: 10, top: 10 }}>
          <Dropdown>
            <Dropdown.Toggle variant="link" size="sm" className="p-0">
              ⋮
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleDelete}>Delete comment</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      <div className="border-start ps-2 mb-2" style={{ borderLeftWidth: "3px" }}>
        <span style={{ fontSize: 14 }} className="d-block text-muted mb-1">
          "{quotedText}"
        </span>
        {unsaved && (
          <span className="badge bg-warning" style={{ width: "fit-content" }}>
            Unsaved
          </span>
        )}
      </div>

      <div className="mt-2">
        {text && <Comment 
          key={0} 
          userId={meData?.me?.id ?? ''}
          userEmail={meData?.me?.email ?? ''}
          initials={getUserInitials(meData?.me)} 
          text={text} 
          createdDateTime={createdDateTime} 
        />}
      </div>

      {comments.map((comment, i) => (
        <div key={i} className="mt-2">
          <Comment 
            userId={comment.user?.id ?? ''}
            userEmail={comment.user?.email ?? ''}
            initials={getUserInitials(comment.user ?? undefined)} 
            text={comment.text} 
            createdDateTime={comment.createdDateTime} 
          />
        </div>
      ))}

      {!isNew && id && !id.startsWith('temp-') && !showReplyInput && (
        <div className="d-flex justify-content-end">
          <button 
            className="btn btn-sm btn-primary"
            onClick={() => setShowReplyInput(true)}
          >
            Add Comment
          </button>
        </div>
      )}

      {(showInputAndButtons || showReplyInput) && (
        <>
          <div className="d-flex align-items-center mt-3" style={{ fontSize: 12 }}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{user?.email || meData?.me?.email || 'Anonymous'}</Tooltip>}
            >
              <div
                className="d-flex align-items-center flex-shrink-0 justify-content-center border rounded-circle"
                style={{
                  width: 30,
                  height: 30,
                }}
              >
                {getUserInitials(meData?.me)}
              </div>
            </OverlayTrigger>
            <input
              type="text"
              className="form-control ms-2"
              placeholder={"Add a comment..."}
              aria-label="Comment"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />
          </div>

          <div className="d-flex justify-content-end gap-2 mt-2">
            {(id.startsWith('temp-') || showReplyInput) && 
              <button 
                className="btn btn-outline-secondary btn-sm"
                onClick={() => {
                  setCommentText("");
                  if (showReplyInput) {
                    setShowReplyInput(false);
                  } else {
                    onCancel?.();
                  }
                }}
              >
                Cancel
              </button>
            }
            <button 
              className="btn btn-primary btn-sm"
              onClick={handleSave}
              disabled={!commentText.trim()}
            >
              Save
            </button>
          </div>
        </>
      )}
    </div>
  );
}; 