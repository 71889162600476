import Spinner from "react-bootstrap/Spinner";
import { useMutation } from "@apollo/client";
import { useEffect, useRef, useCallback } from "react";
import { CREATE_PHONECALL_SECURE_STREAM } from "../mutations/Cases";
import { sendAmplitudeData } from "../services/amplitudeClient";

interface AudioPlayerProps {
  phonecallId: string;
  onTimeUpdate?: (currentTime: number) => void;
  onPlayingStateChange?: (isPlaying: boolean) => void;
  onSeekRequest?: (seekFn: (time: number) => void) => void;
}

export function AudioPlayer({
  phonecallId,
  onTimeUpdate,
  onPlayingStateChange,
  onSeekRequest
}: AudioPlayerProps) {
  const [createPhonecallSecureStreamFunction, { data, error, loading }] =
    useMutation(CREATE_PHONECALL_SECURE_STREAM);

  const audioRef = useRef<HTMLAudioElement>(null);

  //const [isPlaying, setIsPlaying] = useState(false);

  const seekTo = useCallback((time: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = time;
      sendAmplitudeData("phonecall-seek", { id: phonecallId });
    }
  }, [phonecallId]);
  const handleOnPlay = () => {
    //setIsPlaying(true);
    onPlayingStateChange?.(true);
    sendAmplitudeData("phonecall-play", { id: phonecallId });
  };

  const handleOnPause = () => {
    //setIsPlaying(false);
    onPlayingStateChange?.(false);
    sendAmplitudeData("phonecall-pause", { id: phonecallId });
  };

  const handleOnSeek = () => {
    sendAmplitudeData("phonecall-seek", { id: phonecallId });
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      onTimeUpdate?.(audioRef.current.currentTime);
    }
  };

  useEffect(() => {
    if (phonecallId == null) {
      return;
    }
    createPhonecallSecureStreamFunction({ variables: { phonecallId } });
  }, [createPhonecallSecureStreamFunction, phonecallId]);

  // Reset playing state when audio source changes
  useEffect(() => {
    //setIsPlaying(false);
    onPlayingStateChange?.(false);
  }, [data?.createPhonecallSecureStream.string, onPlayingStateChange]);

  useEffect(() => {
    onSeekRequest?.(seekTo);
  }, [seekTo, onSeekRequest]);

  if (loading)
    return (
      <div className="d-flex align-items-center">
        <Spinner animation="border" role="status" className="me-3">
          <span className="visually-hidden">Loading audio</span>
        </Spinner>
        <span>Loading audio</span>
      </div>
    );

  if (error) return <div>Audio not available.</div>;

  return (
    <audio
      ref={audioRef}
      className="shadow rounded-5 w-100"
      controls
      onPlay={handleOnPlay}
      onPause={handleOnPause}
      onSeeking={handleOnSeek}
      onTimeUpdate={handleTimeUpdate}
    >
      <source src={data?.createPhonecallSecureStream.string} type="audio/mpeg" />
    </audio>
  );
}