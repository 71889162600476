import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);
export default function TimeSpan({ value }) {
  if (value === null) return <span></span>;

  var duration = dayjs.duration(value);
  var hours = duration.format("H[h]").replace(/\b0h\b/, "");
  var minutes = duration.format("m[m]");
  var seconds = `${Math.round(duration.seconds())}s`;
  return <span>{value && `${hours} ${minutes} ${seconds}`}</span>;
}
