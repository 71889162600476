import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface CommentProps {
  userId: string;
  userEmail: string;
  initials: string;
  text: string;
  createdDateTime: string;
}

export const Comment = ({ userId, userEmail, initials, text, createdDateTime }: CommentProps) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  };

  return (
    <div className="d-flex align-items-center p-2" style={{ fontSize: 14 }}> 
    
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{userEmail || 'Anonymous'}</Tooltip>}
      >
            <div
        className="d-flex align-items-center flex-shrink-0 justify-content-center border rounded-circle"
        style={{
          width: 30,
          height: 30,
        }}
      >
        {initials}
      </div>
      </OverlayTrigger>
      <div className="ms-2 flex-grow-1">
        {text}
        <div className="text-muted mt-1" style={{fontSize: 10}}>
          {formatDate(createdDateTime)}
        </div>
      </div> 
    </div>
  );
}; 