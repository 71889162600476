interface TagProps {
  name: string;
  color?: string;
  onClick?: () => void;
  showDot?: boolean;
}

export const Tag = ({ name, color = '#6c757d', onClick, showDot = false }: TagProps) => {
  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <div 
      className={`d-inline-flex align-items-center ${onClick ? 'cursor-pointer hover-bg-light p-2' : ''}`}
      onClick={handleClick}
      onMouseDown={onClick ? (e) => e.preventDefault() : undefined}
    >
      {showDot && (
        <div 
          className="rounded-circle me-2"
          style={{
            width: '12px',
            height: '12px',
            backgroundColor: color,
            border: 'none'
          }}
        />
      )}
      <span 
        className={`badge me-1`}
        style={{ backgroundColor: color }}
      >
        {name}
      </span>
    </div>
  );
}; 