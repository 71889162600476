import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
//import Stack from "react-bootstrap/Stack";
//import { Badge } from "react-bootstrap";
import { ModalUploadCall } from "../../../components/ModalUploadCall";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import DateTime from "../../../components/DateTime";
//import { useEffect } from "react";
import { GET_CALLS, GET_CALLS_FOLLOWED, GET_CALLS_TAGGED, GET_CALLS_TAGGED_FOLLOWED } from "../../../pages/Call/gql/queries";
import TimeSpan from "../../../components/TimeSpan";
import { sendAmplitudeData } from "../../../services/amplitudeClient";
import { SentimentValueIcon } from "../../../components/SentimentValueIcon";
import { useLocalStorage } from "usehooks-ts";
//import Badge from "react-bootstrap/Badge";
import { Tag } from "../../../components/Tag";

interface CallsTableProps {
  search: any;
  startDate: any;
  endDate: any;
  filterBookmarked: any;
  filterSentimentValue: any;
  filterCallDirection: any;
  filterTags: any;
  showUploadCallModal: any;
  setShowUploadCallModal: any;
  isValidDate: any;
  filterMinCallDuration: {
    enabled: boolean;
    seconds: number;
  };
}

export const CallsTable = ({
  search,
  startDate,
  endDate,
  filterBookmarked,
  filterSentimentValue,
  filterCallDirection,
  filterTags,
  showUploadCallModal,
  setShowUploadCallModal,
  isValidDate,
  filterMinCallDuration,
}: CallsTableProps) => {
  const [paginationInput, setPaginationInput] = useLocalStorage("pagination", {
    first: 10,
    after: null,
    last: null,
    before: null,
  });

  const startPage = () => {
    sendAmplitudeData("dashboard-page-start", {});
    setPaginationInput({
      first: 10,
      after: null,
      last: null,
      before: null,
    });
  };

  const nextPage = () => {
    sendAmplitudeData("dashboard-page-next", {});
    setPaginationInput({
      first: 10,
      after: data?.phonecalls.pageInfo.endCursor,
      last: null,
      before: null,
    });
  };

  const prevPage = () => {
    sendAmplitudeData("dashboard-page-previous", {});
    setPaginationInput({
      first: null,
      after: null,
      last: 10,
      before: data?.phonecalls.pageInfo.startCursor,
    });
  };

  const { loading, data, refetch } = useQuery(
    (() => {
      switch (true) {
        case filterBookmarked && filterTags.length === 0:
          //console.log('Using GET_CALLS_FOLLOWED query - bookmarked calls only');
          return GET_CALLS_FOLLOWED;
        case !filterBookmarked && filterTags.length > 0:
          //console.log('Using GET_CALLS_TAGGED query - tagged calls only');
          return GET_CALLS_TAGGED;
        case filterBookmarked && filterTags.length > 0:
          //console.log('Using GET_CALLS_TAGGED_FOLLOWED query - bookmarked and tagged calls');
          return GET_CALLS_TAGGED_FOLLOWED;
        default:
          //console.log('Using GET_CALLS query - all calls');
          return GET_CALLS;
      }
    })(),
    {
      variables: {
        ...paginationInput,
        search: search,
        startDate: isValidDate(startDate)
          ? startDate
          : new Date(0).toISOString(),
        endDate: isValidDate(endDate)
          ? endDate
          : new Date(9640000000000).toISOString(),
        filterSentiment:
          filterSentimentValue.length === 0
            ? ["POSITIVE", "NEUTRAL", "NEGATIVE", null]
            : filterSentimentValue,
        filterCallDirection:
          filterCallDirection.length === 0
            ? ["INBOUND", "OUTBOUND"]
            : filterCallDirection,
        filterTags: filterTags.length === 0 ? [null] : filterTags,
        filterMinCallDuration: filterMinCallDuration.enabled 
          ? `PT${filterMinCallDuration.seconds}S`
          : "PT0S"
      },
    },
  );

  return (
    <>
      {loading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {!loading && (
        <>
          <Table striped hover borderless className="shadow mb-4">
            <thead className="rounded-top">
              <tr>
                <th></th>
                <th>Call Details</th>
                <th>Participants</th>
                <th>Call Info</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.phonecalls?.nodes?.map((item) => (
                <tr key={item.id} className="align-middle" style={{ height: '100px' }}>
                  <td className="text-center">
                    {item?.follows?.length > 0 && (
                      <span>
                        <i className="bi-bookmark-fill"></i>
                      </span>
                    )}
                  </td>
                  <td>
                    <div className="d-flex flex-column">
                      <span>
                        <strong>Date:</strong> <DateTime value={item.eventDateTime} />
                      </span>
                      <span>
                        <strong>Duration:</strong> <TimeSpan value={item.callDuration} />
                      </span>
                      <span className="mt-1">
                        <strong>Tags:</strong>{" "}
                        {item.tags?.map((tag) => (
                          <Tag 
                            key={tag.id}
                            name={tag.name}
                            color={tag.color}
                          />
                        ))}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-column">
                      <div>
                        <strong>From:</strong> {item.callingAgent || item.callingPartyNumber}
                      </div>
                      <div>
                        <strong>To:</strong> {item.dialledAgent || item.dialledPartyNumber}
                      </div>
                      <div>
                        {item.callDirection === "INBOUND" ? (
                          <i className="bi-telephone-inbound" title="Inbound"></i>
                        ) : (
                          <i className="bi-telephone-outbound" title="Outbound"></i>
                        )}
                        {" "}{SentimentValueIcon(item?.sentimentValue)}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-column">
                      {item.keyFacts?.split('\n')
                        .slice(0, 3)
                        .map((fact, index) => (
                          <div key={index} className="text-truncate" style={{ maxWidth: '600px' }}>
                            • {fact}
                          </div>
                        ))}
                    </div>
                  </td>
                  <td>
                    <ButtonGroup aria-label="Basic example" size="sm">
                      <Link
                        className="btn btn-primary"
                        to={`/call/${item.id}`}
                        state={{
                          data: item,
                          id: item.id,
                        }}
                      >
                        View
                      </Link>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="text-center">
            <ButtonGroup size="sm">
              <Button
                disabled={!data?.phonecalls.pageInfo.hasPreviousPage}
                onClick={startPage}
              >
                Start
              </Button>
              <Button
                disabled={!data?.phonecalls.pageInfo.hasPreviousPage}
                onClick={prevPage}
              >
                Previous
              </Button>
              <Button
                disabled={!data?.phonecalls.pageInfo.hasNextPage}
                onClick={nextPage}
              >
                Next
              </Button>
            </ButtonGroup>
          </div>
          <ModalUploadCall
            show={showUploadCallModal}
            setShow={setShowUploadCallModal}
            reload={refetch}
          />
        </>
      )}
    </>
  );
};
